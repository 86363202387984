import { initialState } from "../initialState";
import { updateObject } from "./utility";

const init = {
  successEdit: false,
  successAdd: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case "EDIT_USER":
      return updateObject(state, {
        successEdit: true,
      });
    case "ADD_USER":
      return updateObject(state, {
        successAdd: true,
      });
    default:
      return state;
  }
}
