import { updateObject } from "./utility";

const init = {
  listHistoryTransaction: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  listHistoryDigital: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  loadingHistory: false,
  messageHistory: "",
  successHistory: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_TRANS_LIST_START":
      return updateObject(state, {
        listHistoryTransaction: action.payload,
        loadingHistory: true,
        messageHistory: "",
      });
    case "GET_HISTORY_TRANSAKSI_SUCCESS":
      return updateObject(state, {
        listHistoryTransaction: action.payload,
        successHistory: true,
        loadingHistory: false,
      });
    case "GET_HISTORY_TRANSAKSI_FAILED":
      return updateObject(state, {
        successHistory: false,
        loadingHistory: false,
        messageHistory: action.payload,
        listHistoryTransaction: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });
    case "GET_HISTORY_DIGITAL_SUCCESS":
      return updateObject(state, {
        listHistoryTransaction: action.payload,
        successHistory: true,
        loadingHistory: false,
      });
    case "GET_HISTORY_DIGITAL_FAILED":
      return updateObject(state, {
        successHistory: false,
        loadingHistory: false,
        messageHistory: action.payload,
        listHistoryDigital: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });

    default:
      return state;
  }
}
