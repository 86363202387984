import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./redux/store";
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import "moment/locale/id";
import moment from "moment";
import { QueryClient, QueryClientProvider } from "react-query";
moment.locale("id");

const { store } = configureStore();
const muiTheme = getMuiTheme({});
const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider muiTheme={muiTheme}>
          <App />
        </MuiThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
