import { updateObject } from "./utility";

const init = {
  listTransaction: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  loadingTransactionList: false,
  messageTransactionList: "",
  successTransactionList: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_TRANS_LIST_START":
      return updateObject(state, {
        listTransaction: action.payload,
        loadingTransactionList: true,
        messageTransactionList: "",
      });
    case "GET_TRANS_LIST_SUCCESS":
      return updateObject(state, {
        listTransaction: action.payload,
        successTransactionList: true,
        loadingTransactionList: false,
      });
    case "GET_TRANS_LIST_FAILED":
      return updateObject(state, {
        // listTransaction: action.payload,
        successTransactionList: false,
        loadingTransactionList: false,
        messageTransactionList: action.payload,
        listTransaction: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });

    default:
      return state;
  }
}
