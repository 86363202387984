import { updateObject } from "./utility";

const init = {
  listReportWalletTrx: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
  },
  loadingReportWalletTrxList: false,
  messageReportWalletTrxList: "",
  successReportWalletTrxList: false,
  loadingGetAll: false,
  successGetAll: false,
  messageGetAll: "",
  page: 1,
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_ALL_WALLET_TRX_REQUEST":
      return updateObject(state, {
        loadingGetAll: true,
      });
    case "GET_REPORT_WALLET_TRX_SUCCESS":
      return updateObject(state, {
        listReportWalletTrx: action.payload,
        loadingGetAll: false,
        successGetAll: true,
      });
    case "GET_REPORT_WALLET_TRX_FAILED":
      return updateObject(state, {
        loadingGetAll: false,
        successGetAll: false,
        messageGetAll: action.payload,
        listReportWalletTrx: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });
    case "SET_PAGE":
      return updateObject(state, {
        page: action.payload,
      });

    default:
      return state;
  }
}
