const init = {
  title: "Judul",
};

function toolbarReducer(state = init, action) {
  switch (action.type) {
    case "SET_TOOLBAR":
      return {
        title: action.payload,
      };

    default:
      return state;
  }
}

export default toolbarReducer;
