import { updateObject } from "./utility";

const init = {
  listReportTransferOut: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  loadingReportTransferOutList: false,
  messageReportTransferOutList: "",
  successReportTransferOutList: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_REPORT_TRANSOUT_LIST_SUCCESS":
      return updateObject(state, {
        listReportTransferOut: action.payload,
        successReportTransferOutList: true,
        loadingReportTransferOutList: false,
      });
    case "GET_REPORT_TRANSOUT_LIST_FAILED":
      return updateObject(state, {
        successReportTransferOutList: false,
        loadingReportTransferOutList: false,
        messageReportTransferOutList: action.payload,
        listReportTransferOut: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });

    default:
      return state;
  }
}
