import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Spinner } from "reactstrap";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import PublicRoute from "./components/PublicRoute";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <Spinner size="lg" color="warning" />
  </div>
);

const NewLayout = lazy(() => import("./containers/NewLayout"));

const Login = lazy(() => import("./views/Pages/Login/Login"));
const ForgotPassword = lazy(() =>
  import("./views/Pages/ForgotPassword/Forgot")
);
const ValidateForgotPassword = lazy(() =>
  import("./views/Pages/ForgotPassword/ValidateForgotPassword")
);
const EmailValidate = lazy(() =>
  import("./views/Pages/ForgotPassword/EmailValidate")
);

const Page404 = lazy(() => import("./views/Pages/404"));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading()}>
          <Switch>
            <PublicRoute
              exact
              path="/login"
              name="Login Page"
              component={Login}
            />
            <PublicRoute
              exact
              path="/forgot-password"
              name="Forgot Password"
              component={ForgotPassword}
            />
            <PublicRoute
              exact
              path="/forgot-password/reset-password"
              name="ValidateForgotPassword"
              component={ValidateForgotPassword}
            />
            <PublicRoute
              exact
              path="/forgot-password/validate"
              name="Validate Email"
              component={EmailValidate}
            />
            <Route exact path="/404" name="404Page" component={Page404} />

            <Route
              path="/"
              name="Home"
              render={(props) => <NewLayout {...props} />}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
