import { initialState } from "../initialState";
import { updateObject } from "./utility";

const init = {
  listUsers: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  listUsersDropDown: [],
  loadingUserList: false,
  messageUserList: "",
  successUserList: false,
  successEdit: false,
  successAdd: false,
  page: 1,
  search: "",
  limit: 10,
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_USERS_LIST_SUCCESS":
      return updateObject(state, {
        listUsers: action.payload,
        successUserList: true,
        loadingUserList: false,
      });
    case "GET_USERS_LIST_FAILED":
      return updateObject(state, {
        successUserList: false,
        loadingUserList: false,
        messageUserList: action.payload,
        listUsers: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });
    case "GET_USERS_DROP_SUCCESS":
      return updateObject(state, {
        listUsersDropDown: action.payload,
        loadingUser: false,
        successUser: true,
      });
    case "GET_USERS_DROP_FAILED":
      return updateObject(state, {
        messageUser: action.payload,
        loadingUser: false,
        successUser: false,
        listUsersDropDown: [],
      });
    case "GET_USERS_DROP_START":
      return updateObject(state, {
        listUsers: [],
        loadingUser: true,
        messageUser: "",
      });
    case "EDIT_USER":
      return updateObject(state, {
        successEdit: true,
      });
    case "ADD_USER":
      return updateObject(state, {
        successAdd: true,
      });
    case "SET_PAGE":
      return updateObject(state, {
        page: action.payload,
      });
    case "SET_LIMIT":
      return updateObject(state, {
        limit: action.payload,
      });
    case "SET_SEARCH":
      return updateObject(state, {
        search: action.payload,
      });
    default:
      return state;
  }
}
