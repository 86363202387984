import { updateObject } from "./utility";

const initialState = {
  provinceList: [],
  loadingMasterData: false,
  loadingFormClient: false,
  messageMasterData: "",
  successMasterData: false,
  successEditClient: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_PROVINCE_LIST_SUCCESS":
      return updateObject(state, {
        provinceList: action.payload,
        loadingMasterData: false,
        successMasterData: true,
      });
    case "GET_PROVINCE_LIST_FAILED":
      return updateObject(state, {
        loadingMasterData: true,
        messageMasterData: "",
        provinceList: [],
      });

    default:
      return state;
  }
}
