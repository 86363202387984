import { initialState } from "../initialState";
import { updateObject } from "./utility";

const init = {
  listSupervisor: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  listOperation: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  listSuperAdmin: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  listUsersDropDown: [],
  loadingUserList: false,
  messageUserList: "",
  successUserList: false,
  successEdit: false,
  successAdd: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_SUPERVISOR_LIST_SUCCESS":
      return updateObject(state, {
        listSupervisor: action.payload,
        successUserList: true,
        loadingUserList: false,
      });
    case "GET_SUPERVISOR_LIST_FAILED":
      return updateObject(state, {
        successUserList: false,
        loadingUserList: false,
        messageUserList: action.payload,
        listSupervisor: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });
    case "GET_SUPERADMIN_LIST_SUCCESS":
      return updateObject(state, {
        listSuperAdmin: action.payload,
        successUserList: true,
        loadingUserList: false,
      });
    case "GET_SUPERADMIN_LIST_FAILED":
      return updateObject(state, {
        successUserList: false,
        loadingUserList: false,
        messageUserList: action.payload,
        listSuperAdmin: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });
    case "GET_OPERATION_LIST_SUCCESS":
      return updateObject(state, {
        listOperation: action.payload,
        successUserList: true,
        loadingUserList: false,
      });
    case "GET_OPERATION_LIST_FAILED":
      return updateObject(state, {
        successUserList: false,
        loadingUserList: false,
        messageUserList: action.payload,
        listOperation: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });
    case "UPDATE_APPROVAL":
      return updateObject(state, {
        successEdit: true,
      });
    default:
      return state;
  }
}
