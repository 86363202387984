import { updateObject } from "./utility";

const init = {
  dashboard: [],
  transactions: [],
  memberRank: [],
  productRank: [],
  productPartner: [],
  amountPartner: [],
  statusTransaction: [],
  userVerif: [],
  userCompar: [],
  userGrowth: [],
  userAmount: [],
  userReceipt: [],
  topTenTrans: [],
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_DASHBOARD":
      return updateObject(state, {
        dashboard: action.payload,
      });
    case "GET_TRANSACTIONS":
      return updateObject(state, {
        transactions: action.payload,
      });
    case "GET_MEMBER_RANK":
      return updateObject(state, {
        memberRank: action.payload,
      });
    case "GET_PRODUCT_RANK":
      return updateObject(state, {
        productRank: action.payload,
      });
    case "GET_PRODUCT_PARTNER":
      return updateObject(state, {
        productPartner: action.payload,
      });
    case "GET_AMOUNT_PARTNER":
      return updateObject(state, {
        amountPartner: action.payload,
      });
    case "GET_STATUS_TRANSACTION":
      return updateObject(state, {
        statusTransaction: action.payload,
      });
    case "GET_USER_VERIF":
      return updateObject(state, {
        userVerif: action.payload,
      });
    case "GET_USER_COMPARISON":
      return updateObject(state, {
        userCompar: action.payload,
      });
    case "GET_USER_GROWTH":
      return updateObject(state, {
        userGrowth: action.payload,
      });
    case "GET_USER_AMOUNT":
      return updateObject(state, {
        userAmount: action.payload,
      });
    case "GET_USER_RECEIPT":
      return updateObject(state, {
        userReceipt: action.payload,
      });
    case "GET_TOP_TEN":
      return updateObject(state, {
        topTenTrans: action.payload,
      });

    default:
      return state;
  }
}
