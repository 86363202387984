import { updateObject } from "./utility";
import { initialState } from "../initialState";

const init = {
  masterProduct: [],
  masterArea: [],
  masterPayment: [],
  masterStatus: [],
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_MASTER_PRODUCT":
      return updateObject(state, {
        masterProduct: action.payload,
      });
    case "GET_MASTER_AREA":
      return updateObject(state, {
        masterArea: action.payload,
      });
    case "GET_MASTER_STATUS":
      return updateObject(state, {
        masterStatus: action.payload,
      });
    case "GET_MASTER_PAYMENT":
      return updateObject(state, {
        masterPayment: action.payload,
      });

    default:
      return state;
  }
}
