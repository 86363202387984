import { updateObject } from "./utility";

const init = {
  profile: [],
  photoProfile: [],
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_PROFILE":
      return updateObject(state, {
        profile: action.payload,
      });
    case "EDIT_PROFILE":
      return updateObject(state, {
        profile: action.payload,
      });
    case "GET_PHOTOS":
      return updateObject(state, {
        photoProfile: action.payload,
      });
    case "EDIT_PHOTOS":
      return updateObject(state, {
        photoProfile: action.payload,
      });

    default:
      return state;
  }
}
