import { updateObject } from "./utility";

const init = {
  listRoles: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  loadingRolesList: false,
  messageRolesList: "",
  successRolesList: false,
  dropdownRole: [],
  loadingRole: false,
  messageRole: "",
  successRole: false,
  userRole: [],
  loadingUserRole: false,
  messageUserRole: "",
  successUserRole: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_ROLE_DROP_SUCCESS":
      return updateObject(state, {
        dropdownRole: action.payload,
        loadingRole: false,
        successRole: true,
      });
    case "GET_ROLE_DROP_START":
      return updateObject(state, {
        loadingRole: true,
        messageRole: "",
        dropdownRole: [],
      });
    case "GET_ROLE_DROP_FAILED":
      return updateObject(state, {
        messageRole: action.payload,
        loadingRole: false,
        successRole: false,
        dropdownRole: [],
      });
    case "GET_USER_ROLE_SUCCESS":
      return updateObject(state, {
        userRole: action.payload,
        loadingUserRole: false,
        successUserRole: true,
      });
    case "GET_USER_ROLE_START":
      return updateObject(state, {
        loadingUserRole: true,
        messageUserRole: "",
        userRole: [],
      });
    case "GET_USER_ROLE_FAILED":
      return updateObject(state, {
        messageUserRole: action.payload,
        loadingUserRole: false,
        successUserRole: false,
        userRole: [],
      });
    case "GET_ROLE_LIST_START":
      return updateObject(state, {
        listRoles: action.payload,
        loadingRolesList: true,
        messageRolesList: "",
      });
    case "GET_ROLE_LIST_SUCCESS":
      return updateObject(state, {
        listRoles: action.payload,
        successRolesList: true,
        loadingRolesList: false,
      });
    case "GET_ROLE_LIST_FAILED":
      return updateObject(state, {
        // listRoles: action.payload,
        successRolesList: false,
        loadingRolesList: false,
        messageRolesList: action.payload,
        listRoles: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });

    default:
      return state;
  }
}
