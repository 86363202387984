import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getCookie } from "../utils/cookie";

function PublicRoute({ component: Component, ...rest }) {
  const isLogin = getCookie("token-wallet-webadmin");
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLogin ? <Component {...props} /> : <Redirect to="/history-trx" /> //route changed
      }
    />
  );
}

export default PublicRoute;
