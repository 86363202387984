import { updateObject } from "./utility";

const initialState = {
  listClient: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  lastUrlParam: "",
  detailClient: {},
  dropdownClient: [],
  loadingClient: false,
  loadingFormClient: false,
  messageClient: "",
  successClient: false,
  successEditClient: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_CLIENT_DROP_SUCCESS":
      return updateObject(state, {
        dropdownClient: action.payload,
        loadingClient: false,
        successClient: true,
      });
    case "GET_CLIENT_DROP_START":
      return updateObject(state, {
        loadingClient: true,
        messageClient: "",
        dropdownClient: [],
      });
    case "GET_CLIENT_DROP_FAILED":
      return updateObject(state, {
        messageClient: action.payload,
        loadingClient: false,
        successClient: false,
        dropdownClient: [],
      });
    case "GET_CLIENT_SUCCESS":
      return updateObject(state, {
        listClient: action.payload,
        loadingClient: false,
        successClient: true,
      });
    case "GET_CLIENT_FAILED":
      return updateObject(state, {
        messageClient: action.payload,
        loadingClient: false,
        successClient: false,
        listClient: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });
    case "GET_CLIENT_START":
      return updateObject(state, {
        listClient: action.payload,
        loadingClient: true,
        messageClient: "",
      });
    case "GET_DETAIL_CLIENT_SUCCESS":
      return updateObject(state, {
        loadingFormClient: false,
        detailClient: action.payload,
      });
    case "SET_CLIENT_URL_PARAM":
      return updateObject(state, {
        lastUrlParam: action.payload,
      });
    default:
      return state;
  }
}
