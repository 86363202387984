import { combineReducers } from "redux";
import userReducer from "./userReducer";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import toolbarReducer from "./toolbarReducer";
import dashboardReducer from "./dashboardReducer";
import transactionReducer from "./transactionReducer";
import transferOutReducer from "./transferOutReducer";
import topUpReducer from "./topUpReducer";
import reportTransactionReducer from "./reportTransactionReducer";
import rolesReducer from "./rolesReducer";
import userRoleReducer from "./userRoleReducer";
import clientReducer from "./clientReducer";
import permissionReducer from "./permissionReducer";
import masterApiReportReducer from "./masterApiReportReducer";
import approvalReducer from "./approvalReducer";
import adjPassportReducer from "./adjPassportReducer";
import masterDataReducer from "./masterDataReducer";
import deleteHistoryReducer from "./deleteHistoryReducer";
import merchantReducer from "./merchantReducer";
import merchantListReducer from "./merchantListReducer";
import historyTrxReducer from "./historyTrxReducer";
import walletTransactionReducer from "./walletTransactionReducer";
import masterRateReducer from "./masterRateReducer";

export default combineReducers({
  profile: profileReducer,
  auth: authReducer,
  user: userReducer,
  toolbar: toolbarReducer,
  dashboard: dashboardReducer,
  transaction: transactionReducer,
  reportTransaction: reportTransactionReducer,
  transferOut: transferOutReducer,
  topUp: topUpReducer,
  historyTrx: historyTrxReducer,
  walletTrx: walletTransactionReducer,
  roles: rolesReducer,
  userRole: userRoleReducer,
  client: clientReducer,
  permission: permissionReducer,
  masterApiReport: masterApiReportReducer,
  approval: approvalReducer,
  adjPassport: adjPassportReducer,
  masterData: masterDataReducer,
  deleteHistory: deleteHistoryReducer,
  merchant: merchantReducer,
  merchantList: merchantListReducer,
  masterRate: masterRateReducer,
});

// const rootReducer = (state, action) => {
//   if (action.type === "USER_LOGOUT") {
//     state = undefined;
//   }
//   return appReducer(state, action);
// };

// export default rootReducer;
