import { updateObject } from "./utility";
import { LOGIN } from "../actions/actionType";
import { initialState } from "../initialState";

const init = {
  profile: [],
  changePassword: [],
};

export default function (state = init, action) {
  switch (action.type) {
    case LOGIN:
      return updateObject(state, {
        profile: action.payload,
      });
    case "CHANGE_PASSWORD":
      return updateObject(state, {
        changePassword: action.payload,
      });

    default:
      return state;
  }
}
