import { updateObject } from "./utility";

const init = {
  listReportHistoryTrx: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
  },
  loadingReportHistoryTrxList: false,
  messageReportHistoryTrxList: "",
  successReportHistoryTrxList: false,
  loadingGetAll: false,
  successGetAll: false,
  messageGetAll: "",
  page: 1,
  status: "",
};

export default function (state = init, action) {
  // console.log(action?.payload);
  switch (action.type) {
    case "GET_ALL_HISTORY_TRX_REQUEST":
      return updateObject(state, {
        loadingGetAll: true,
      });
    case "GET_REPORT_HISTORY_TRX_SUCCESS":
      return updateObject(state, {
        listReportHistoryTrx: action.payload,
        loadingGetAll: false,
        successGetAll: true,
      });
    case "GET_REPORT_HISTORY_TRX_FAILED":
      return updateObject(state, {
        loadingGetAll: false,
        successGetAll: false,
        messageGetAll: "etst",
        status: action?.payload?.status,
        listReportHistoryTrx: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });
    case "SET_PAGE":
      return updateObject(state, {
        page: action.payload,
      });

    default:
      return state;
  }
}
