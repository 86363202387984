import { updateObject } from "./utility";

const init = {
  listMerchant: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  loadingMerchantList: false,
  messageMerchantList: "",
  successMerchantList: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_MERCHANT_LIST_SUCCESS":
      return updateObject(state, {
        listMerchant: action.payload,
        successMerchantList: true,
        loadingMerchantList: false,
      });
    case "GET_REPORT_TOP_UP_FAILED":
      return updateObject(state, {
        successMerchantList: false,
        loadingMerchantList: false,
        messageMerchantList: action.payload,
        listMerchant: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });

    default:
      return state;
  }
}
