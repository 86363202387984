import { updateObject } from "./utility";

const initialState = {
  data: [],
  loadingPermission: false,
  loadingFormPermission: false,
  messagePermission: "",
  successPermission: false,
  successEditPermission: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_PERMISSION_SUCCESS":
      return updateObject(state, {
        data: action.payload,
        loadingPermission: false,
        successPermission: true,
      });
    case "GET_PERMISSION_FAILED":
      return updateObject(state, {
        messagePermission: action.payload,
        loadingPermission: false,
        successPermission: false,
        data: [],
      });
    case "GET_PERMISSION_START":
      return updateObject(state, {
        data: [],
        loadingPermission: true,
        messagePermission: "",
      });
    case "POST_PERMISSION_START":
      return updateObject(state, {
        loadingFormPermission: true,
        messagePermission: "",
      });
    case "POST_PERMISSION_FAILED":
      return updateObject(state, {
        loadingFormPermission: false,
        messagePermission: action.payload,
        successEditPermission: false,
      });
    case "POST_PERMISSION_SUCCESS":
      return updateObject(state, {
        loadingFormPermission: false,
        messagePermission: "",
        successEditPermission: true,
      });
    case "RESET_PERMISSION_FORM_ACTION":
      return updateObject(state, {
        loadingFormPermission: action.payload.loading,
        successEditPermission: action.payload.success,
        messagePermission: action.payload.message,
      });
    default:
      return state;
  }
}
