import { updateObject } from "./utility";

const init = {
  listReportTopUp: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  loadingReportTopUpList: false,
  messageReportTopUpList: "",
  successReportTopUpList: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_REPORT_TOP_UP_SUCCESS":
      return updateObject(state, {
        listReportTopUp: action.payload,
        successReportTopUpList: true,
        loadingReportTopUpList: false,
      });
    case "GET_REPORT_TOP_UP_FAILED":
      return updateObject(state, {
        successReportTopUpList: false,
        loadingReportTopUpList: false,
        messageReportTopUpList: action.payload,
        listReportTopUp: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });

    default:
      return state;
  }
}
