import { updateObject } from "./utility";

const init = {
  listReportTransaction: {
    data: [],
    totalRecord: 0,
    totalPage: 0,
    page: 0,
  },
  loadingReportTransactionList: false,
  messageReportTransactionList: "",
  successReportTransactionList: false,
};

export default function (state = init, action) {
  switch (action.type) {
    case "GET_REPORT_TRANS_LIST_START":
      return updateObject(state, {
        listReportTransaction: action.payload,
        loadingReportTransactionList: true,
        messageReportTransactionList: "",
      });
    case "GET_REPORT_TRANS_LIST_SUCCESS":
      return updateObject(state, {
        listReportTransaction: action.payload,
        successReportTransactionList: true,
        loadingReportTransactionList: false,
      });
    case "GET_REPORT_TRANS_LIST_FAILED":
      return updateObject(state, {
        // listReportTransaction: action.payload,
        successReportTransactionList: false,
        loadingReportTransactionList: false,
        messageReportTransactionList: action.payload,
        listReportTransaction: {
          data: [],
          totalRecord: 0,
          totalPage: 0,
          page: 0,
        },
      });

    default:
      return state;
  }
}
